/** @format */

import React from "react";
import styled from "styled-components";
import img from "../Assets/call-action-bg.jpg";

const Card1 = () => {
  return (
    <div>
      <Container>
        <Main>
          <Test>
            WE ARE DEDICATED TO PROVIDE THE BEST SERVICES <br /> AT THE LOWEST
            POSSIBLE COST
          </Test>
          <Button>GET A QUOTE</Button>
        </Main>
      </Container>
    </div>
  );
};

export default Card1;

const Button = styled.div`
  width: 150px;
  height: 50px;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;

  :hover {
    cursor: pointer;
    background-color: white;
    color: #e53d3a;
  }
`;
const Test = styled.div`
  text-align: center;
  color: white;
  font-size: 40px;
`;
const Main = styled.div`
  width: 100%;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Container = styled.div`
  width: 100%;
  height: 400px;
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`;
