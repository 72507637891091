/** @format */

import React from "react";
import styled from "styled-components";
import img from "../Assets/stat-bg.jpg";
import { AiTwotoneCalendar } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { RiEarthFill } from "react-icons/ri";
const Card3 = () => {
  return (
    <div>
      <Container>
        <Main>
          <Card>
            <Box>
              <Logo>
                <AiTwotoneCalendar size={40} style={{ color: "red" }} />
                15
              </Logo>
              <Test>Offices Worldwide</Test>
            </Box>
            <Box>
              <Logo>
                <BsFillPeopleFill size={40} style={{ color: "red" }} />
                97
              </Logo>
              <Test>Hardworking People</Test>
            </Box>
            <Box>
              <Logo>
                <RiEarthFill size={40} style={{ color: "red" }} />
                12
              </Logo>
              <Test>Countries Covered</Test>
            </Box>
            <Box>
              <Logo>
                <BsFillPeopleFill size={40} style={{ color: "red" }} />
                25
              </Logo>
              <Test>Years of Experiences</Test>
            </Box>
          </Card>
        </Main>
      </Container>
    </div>
  );
};

export default Card3;

const Test = styled.div``;
const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
`;
const Box = styled.div`
  border-left: 1px solid silver;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Card = styled.div`
  width: 1100px;
  height: 150px;
  background-color: white;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;
const Main = styled.div`
  width: 100%;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  width: 100%;
  height: 400px;
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`;
