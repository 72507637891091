/** @format */

import React from "react";
import styled from "styled-components";
import img from "../Assets/tab-ware.png";
import img1 from "../Assets/tab-truck.png";
import img2 from "../Assets/tab-plane.png";

const Card = () => {
  return (
    <div>
      <Container>
        <Main>
          <Left>
            <Top>
              <Icon>
                <Logo>
                  <img src={img1} alt="" />
                </Logo>
                <Test>
                  <p>Land Transport</p>
                </Test>
              </Icon>
              <Icon>
                <Logo>
                  <img src={img2} alt="" />
                </Logo>
                <Test>
                  <p>Worldwide Transport</p>
                </Test>
              </Icon>
              <Icon>
                <Logo>
                  <img src={img} alt="" />
                </Logo>
                <Test>
                  <p>OWn Warehousing</p>
                </Test>
              </Icon>
            </Top>
            <Middle>
              <Land>
                <p>Land Transport</p>
              </Land>
              <Title>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores.
                </p>
              </Title>
            </Middle>
            <Down>Read More</Down>
          </Left>
          <Right>
            <span>About Us</span>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            </p>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip exea...
            </div>
            <Down>Read More</Down>
          </Right>
        </Main>
      </Container>
    </div>
  );
};

export default Card;

const Title = styled.div``;
const Land = styled.div``;
const Test = styled.div`
  font-size: 20px;
  font-weight: 600;
`;
const Logo = styled.div`
  img {
    height: 100px;
  }
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Down = styled.div`
  width: 150px;
  height: 50px;
  color: #e53d3a;
  border: 1px solid #e53d3a;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  border-radius: 5px;

  :hover {
    cursor: pointer;
    background-color: #e53d3a;
    color: white;
  }
`;
const Middle = styled.div`
  width: 600px;
  height: 250px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  p {
    font-size: 20px;
    font-weight: 700;
  }
`;
const Top = styled.div`
  width: 600px;
  height: 170px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;
const Right = styled.div`
  width: 600px;
  height: 553px;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;

  span {
    font-size: 35px;
    font-weight: 600;
  }

  div {
    font-size: 20px;
    font-weight: 600;
    /* margin: 0; */
    /* padding-top: 30px; */
  }

  p {
    padding-top: 30px;
    font-size: 40px;
    font-weight: 600;
    /* margin: 0; */
  }
`;
const Left = styled.div`
  width: 600px;
  height: 553px;
  background-color: #f5f0f0;
`;
const Main = styled.div`
  width: 95%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
