/** @format */

import React from "react";
import styled from "styled-components";

const Blogs = () => {
  return (
    <div>
      <Container id="blog">
        <Main>This Blogs Page</Main>
      </Container>
    </div>
  );
};

export default Blogs;

const ff = styled.div``;
const Main = styled.div``;
const Container = styled.div`
  width: 100%;
  height: 500px;
  background-color: gold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
