/** @format */

import React from "react";
import styled from "styled-components";
import img from "../Assets/cl-logo4.png";
import img1 from "../Assets/cl-logo5.png";
import img2 from "../Assets/cl-logo6.png";
import img3 from "../Assets/cl-logo4.png";
import img4 from "../Assets/cl-logo5.png";
import img5 from "../Assets/cl-logo6.png";

const Card5 = () => {
  return (
    <div>
      <Container>
        <Main>
          <Box>
            <img src={img} alt="" />
          </Box>
          <Box>
            <img src={img1} alt="" />
          </Box>
          <Box>
            {" "}
            <img src={img2} alt="" />
          </Box>
          <Box>
            {" "}
            <img src={img3} alt="" />
          </Box>
          {/* <Box>
            <img src={img4} alt="" />
          </Box>
          <Box>
            <img src={img5} alt="" />
          </Box> */}
        </Main>
      </Container>
    </div>
  );
};

export default Card5;

const Box = styled.div`
  border: 1px solid silver;
  margin: 10px;

  img {
    height: 100px;
  }
`;
const Main = styled.div`
  width: 1000px;
  height: 120px;
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;
const Container = styled.div`
  width: 100%;
  height: 250px;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
`;
