/** @format */

import React from "react";
import Hero from "./Hero";
import Card from "./Card";
import Card1 from "./Card1";
import About from "./About";
import Contact from "./Contact";
import Blogs from "./Blogs";
import GETAQUOTE from "./GETAQUOTE";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import Card5 from "./Card5";

const HomeScreen = () => {
  return (
    <div>
      <Hero />
      <Card />
      <Card1 />
      <Card2 />
      <Card3 />
      <Card4 />
      <Card5 />
    </div>
  );
};

export default HomeScreen;
