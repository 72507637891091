/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import { AiFillPlayCircle } from "react-icons/ai";

const Card4 = () => {
  const [show, setshow] = useState(Boolean);

  const Toggle = () => {
    setshow(!show);
  };
  return (
    <div>
      <Container>
        <Main>
          <AiFillPlayCircle size={60} onClick={Toggle} />
        </Main>
        {show ? (
          <Box>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/d7n16cKR0ks"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Box>
        ) : null}
      </Container>
    </div>
  );
};

export default Card4;

const Box = styled.div`
  width: 400px;
  height: 230px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
`;
const Main = styled.div`
  width: 500px;
  height: 200px;
  background-color: red;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
