/** @format */

import React from "react";
import styled from "styled-components";
import img from "../Assets/ser-cup.png";
import img1 from "../Assets/ser-secure.png";
import img2 from "../Assets/ser-track.png";
import img3 from "../Assets/tab-truck.png";

const Card2 = () => {
  return (
    <div>
      <Container>
        <Main1>
          <span>Why choose us</span>
          <p>
            Lorem ipsum dolor sit amet consectetur to adipiscing elit, sed dot
            eiusmod tempor incididunt labore <br /> et dolore magna aliqua.
            Veniam quis nostrud exercitation ullamco laboris nisiut.
          </p>
        </Main1>
        <Main>
          <Box>
            <Logo>
              <img src={img1} alt="" />
            </Logo>
            <Test>SECURE</Test>
            <Small>
              Sed ut perspiciatis unde omnis iste <br /> natus error sit
              voluptatem accusant <br /> iudol oremq dauri.
            </Small>
          </Box>
          <Box>
            <Logo>
              <img src={img3} alt="" />
            </Logo>
            <Test>FAST DELIVERY</Test>
            <Small>
              Accusan tiused ut perspiciatis unde <br />
              omnis iste natus error sit <br />
              voluptatem dolore mque.
            </Small>
          </Box>
          <Box>
            {" "}
            <Logo>
              <img src={img2} alt="" />
            </Logo>
            <Test>TRACKABLE</Test>
            <Small>
              Voluptatem sed ut perspiciatis unde omnis iste natus error sit
              accusantiu <br /> dolo remque laud.
            </Small>
          </Box>
          <Box>
            {" "}
            <Logo>
              <img src={img} alt="" />
            </Logo>
            <Test>RELIABLE</Test>
            <Small>
              Perspiciatis sed ut unde omnis iste <br /> natus error sit
              voluptatem <br />
              accusantiu dolor emques.
            </Small>
          </Box>
        </Main>
      </Container>
    </div>
  );
};

export default Card2;

const ff = styled.div``;
const Small = styled.div`
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-top: 20px;
`;
const Test = styled.div`
  font-size: 25px;
  font-weight: 700;
  margin-top: 10px;
`;
const Logo = styled.div`
  width: 100px;
  height: 90px;
  border-radius: 8px;
  border: 1px solid #e53d3a;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 90px;
  }
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Main1 = styled.div`
  width: 750px;
  height: 190px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-size: 35px;
    font-weight: 700;
    color: red;
  }

  p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
`;
const Main = styled.div`
  width: 1200px;
  height: 460px;
  /* background-color: gold; */
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;
const Container = styled.div`
  width: 100%;
  height: 500px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
