/** @format */

import { createBrowserRouter } from "react-router-dom";
import Layout from "../Components/Common/Layout";
import HomeScreen from "../Pages/HomeScreen";
import Contact from "../Pages/Contact";
import Blogs from "../Pages/Blogs";
import GETAQUOTE from "../Pages/GETAQUOTE";
import About from "../Pages/About";
import SignIn from "../Pages/SignIn";
import Register from "../Pages/Register";

export const mainRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <HomeScreen />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/blog",
        element: <Blogs />,
      },
      {
        path: "/Get",
        element: <GETAQUOTE />,
      },
    ],
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/register",
    element: <Register />,
  },
]);
