/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import img from "../../Assets/logo.png";
import img1 from "../../Assets/mega.jpg";
import { Link } from "react-router-dom";

import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const Header = () => {
  const [show, setshow] = useState(Boolean);
  const [show1, setshow1] = useState(Boolean);

  const Toggle = () => {
    setshow(!show);
  };
  const Toggle1 = () => {
    setshow1(!show1);
  };
  return (
    <div>
      <Container>
        <Main>
          <Left>
            <img src={img} alt="" />
          </Left>
          <Right>
            <NavLink to="/">
              <p>HOME</p>
            </NavLink>
            <NavLink to="about">
              <p>ABOUT US</p>
            </NavLink>
            <Nav>
              <p>OUR SERVICES</p>
              <MdKeyboardArrowDown onClick={Toggle} />
            </Nav>
            {show ? <Box></Box> : null}
            <NavLink to="blog">
              <p>BLOG</p>
            </NavLink>
            <Nav>
              <p>PAGES</p>
              <MdKeyboardArrowDown
                style={{ marginRight: "30px" }}
                onClick={Toggle1}
              />
              {show ? (
                <Box1>
                  <Image1>
                    <img src={img1} alt="" />
                  </Image1>
                  <Text1>
                    <Text>
                      <Icon>
                        <MdOutlineKeyboardArrowRight />
                        <p></p>
                      </Icon>
                    </Text>
                    <Text>ffffffffff</Text>
                    <Text>ffffffffff</Text>
                  </Text1>
                </Box1>
              ) : null}
            </Nav>
            <NavLink to="contact">
              <p> CONTACT</p>
            </NavLink>
            <NavLink to="Get">
              <button>Get A Quote</button>
            </NavLink>
          </Right>
        </Main>
      </Container>
    </div>
  );
};

export default Header;

const NavLink = styled(Link)`
  text-decoration: none;
`;
const Icon = styled.div``;
const Text = styled.div``;
const Text1 = styled.div`
  width: 900px;
  height: 400px;
  background-color: purple;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 80px;
`;

const Image1 = styled.div`
  width: 500px;
  height: 400px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Box1 = styled.div`
  width: 1200px;
  height: 400px;
  background-color: red;
  position: absolute;
  top: 80px;
  right: 50px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Box = styled.div`
  width: 250px;
  height: 400px;
  background-color: red;
  position: absolute;
  top: 80px;
  right: 530px;
  z-index: 10;
`;
const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 150px;
    height: 60px;
    font-size: 15px;
    font-weight: 800;
    outline: none;
    border: 1px solid red;
    background-color: white;
    border-radius: 5px;

    :hover {
      background-color: red;
      cursor: pointer;
    }
  }

  p {
    margin: 20px;
  }
`;
const Left = styled.div``;

const Main = styled.div`
  width: 90%;
  height: 80px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid black;
  position: fixed;
  z-index: 10;
`;
