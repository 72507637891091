/** @format */

import React from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import pix from "../Assets/unsplash.jpg";
import logo from "../Assets/logo.png";
import { Link } from "react-router-dom";

const Register = () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Container>
      <Left>
        <img src={logo} alt="" />
        <Hold>
          <h1>Welcome Back</h1>
          <p>Fill up form and get your orders made</p>
          <Mainh style={{ marginTop: "25px" }}>
            {" "}
            <InpHold>
              <h3 style={{ marginLeft: "10px" }}>Email</h3>
              <input type="text" placeholder="Input an email" />
            </InpHold>
          </Mainh>
          <Mainh>
            <InpHold>
              <h3>Password</h3>
              <input type="text" placeholder="Input an email" />
            </InpHold>
          </Mainh>
          <Button>Sign in</Button>
          <p style={{ color: "black", marginTop: "40px" }}>
            Already have an account?{" "}
            <Link to="/register" style={{ textDecoration: "none" }}>
              <span style={{ color: "blue" }}>Signup</span>
            </Link>
          </p>
        </Hold>
      </Left>
      <Rights></Rights>
    </Container>
  );
};

export default Register;

const Second = styled.div`
  width: 50%;
  height: 100%;
`;
const Button = styled.div`
  width: 90%;
  height: 50px;
  background-color: #e53d3a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 600;
  margin-top: 20px;
  color: white;
`;
const Mainh = styled.div`
  display: flex;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InpHold = styled.div`
  width: 100%;
  /* background-color: red; */
  margin: 10px;

  h3 {
    font-size: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    margin-bottom: 10px;
  }
  input {
    width: 95%;
    height: 40px;
    border: 1px solid lightgray;
    outline: none;
    padding-left: 10px;
    margin: 0;
  }
`;

const Hold = styled.div`
  width: 100%;
  min-height: 200px;
  /* background-color: gold; */
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 40px;
    margin: 0;
    margin-bottom: 7px;
  }
  p {
    font-size: 16px;
    color: darkgray;
    margin: 0;
  }
`;

// const Right1 = styled.div<{bi:string,}>`
//   width: 50%;
//   height: 100%;
//   background-image: ${({bi})=>bi};
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
// `;
const Rights = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${pix});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const Left = styled.div`
  width: 50%;
  height: 92%;
  /* background-color: pink; */
  padding: 3px;

  img {
    height: 50px;
    margin-top: 20px;
    margin-left: 20px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;
