/** @format */

import React from "react";
import styled from "styled-components";

const GETAQUOTE = () => {
  return (
    <div>
      <Container>
        <Main></Main>
      </Container>
    </div>
  );
};

export default GETAQUOTE;

const ff = styled.div``;
const Main = styled.div`
  width: 800px;
  height: 400px;
  background-color: red;
  margin-top: 40px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
