/** @format */

import React from "react";
import styled from "styled-components";
import img from "../../Assets/sub-btn.png";
import { AiFillMessage, AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

const Footer = () => {
  return (
    <div>
      <Container>
        <Main>
          <Box>
            <Title1>About Us</Title1>
            <Nav>
              Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit,
              sed do eiusmod tempor <br /> incididunt ut labore et dolore magna.
            </Nav>
            <Navs>Read More...</Navs>
            <Box1>
              <input type="text" placeholder="Email For NewsLetter" />
              <img src={img} alt="" />
            </Box1>
          </Box>
          <Box>
            <Title>NAVIGATION </Title>
            <Nav>Home</Nav>
            <Nav>About Us</Nav>
            <Nav>Blog</Nav>
            <Nav>Our services</Nav>
            <Nav>Contact us</Nav>
            <Nav>Sitemap</Nav>
          </Box>
          <Box>
            <Title>OUR SERVICES </Title>
            <Nav>Land Transport</Nav>
            <Nav> Warehousing</Nav>
            <Nav> Fast Delivery</Nav>
            <Nav>Worldwide Transport</Nav>
            <Nav>Sea Freight</Nav>
            <Nav>Reliable</Nav>
          </Box>
          <Box>
            <Title>OUR Contact </Title>
            <Nav>
              <AiFillMessage size={20} />
              1234 Sed ut perspiciatis Road, At vero eos, D58 8975, London.
            </Nav>
            <Nav>
              <BsFillTelephoneFill size={20} />
              Toll Free : (123) 4567 8910 Telephone : (123) 1234 5678 Email :
            </Nav>
            <Nav>
              <AiOutlineMail size={20} /> info@sitename.com Web :
              www.sitename.com
            </Nav>
          </Box>
        </Main>
      </Container>
    </div>
  );
};

export default Footer;

const Box1 = styled.div`
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #383838;
  color: #a19c95;

  input {
    width: 150px;
    height: 30px;
    padding-left: 9px;
  }

  img {
    height: 20px;
  }
`;
const Navs = styled.div`
  color: #a19c95;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
`;

const Nav = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
`;

const Title1 = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
`;
const Title = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 600;
`;

const Box = styled.div`
  /* border-left: 1px solid silver; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
`;
const Main = styled.div`
  width: 1200px;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* background-color: #383838; */
`;
const Container = styled.div`
  width: 100%;
  height: 400px;
  background-color: #383838;
  display: flex;
  align-items: center;
  justify-content: center;
`;
