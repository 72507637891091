/** @format */

import React from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img from "../Assets/banner.jpg";
import img1 from "../Assets/banner-2.jpg";
import img2 from "../Assets/banner-3.jpg";

const Hero = () => {
  const settings = {
    fade: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      <Holder>
        <Slider {...settings}>
          <Container bi={`url(${img})`}>
            <Main1>
              <BigText1>
                <p>
                  PROVIDING FIRST CLASS <br /> LOGISTIC SERVICES
                </p>
              </BigText1>
              <SmallText1>
                {" "}
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  <br />
                  Repudiandae ad mollitia quibusdam esse a harum nulla animi{" "}
                  <br />
                  vero illo eos doloribus modi obcaecati laboriosam <br />{" "}
                  dignissimos fugiat vel, error fugit nostrum.
                </p>
              </SmallText1>
              <Box>
                <Button>OUR SERVICES</Button>
                <Button1>GET A QUOTE</Button1>
              </Box>
            </Main1>
          </Container>
          <Container bi={`url(${img1})`}>
            <Main>
              <BigText>
                <p>
                  WORLD CLASS <br /> SHIPPING SERVICES
                </p>
              </BigText>
              <SmallText>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repudiandae ad mollitia quibusdam esse a harum <br /> nulla
                  animi vero illo eos doloribus modi obcaecati laboriosam{" "}
                  dignissimos fugiat vel, error fugit nostrum.
                </p>
              </SmallText>
              <Box>
                <Our>OUR SERVICES</Our>
                <Get>GET A QUOTE</Get>
              </Box>
            </Main>
          </Container>
          <Container bi={`url(${img2})`}>
            <Main2>
              <BigText2>
                <p>
                  WORLD WIDE <br />
                  TRANSPORT SERVICES
                </p>
              </BigText2>
              <SmallText2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  <br />
                  Repudiandae ad mollitia quibusdam esse a harum <br /> nulla
                  animi vero illo eos doloribus modi obcaecati <br />
                  laboriosam dignissimos fugiat vel, error fugit nostrum.
                </p>
              </SmallText2>
              <Box>
                <Big>OUR SERVICES</Big>
                <Small>GET A QUOTE</Small>
              </Box>
            </Main2>
          </Container>
        </Slider>
      </Holder>
    </div>
  );
};

export default Hero;

const Small = styled.div`
  width: 200px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid silver;
  color: white;
  margin-right: 25px;
`;

const Big = styled.div`
  width: 200px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  background-color: red;

  :hover {
    cursor: pointer;
    background-color: black;
    color: white;
  }
`;

const SmallText = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 500;

  p {
    margin: 0;
  }
`;
const SmallText2 = styled.div`
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  margin-right: 25px;
  margin: 10px;

  p {
    margin: 0;
  }
`;
const SmallText1 = styled.div`
  font-size: 19px;
  font-weight: 700;

  p {
    margin: 0;
  }
`;
const BigText2 = styled.div`
  font-size: 40px;
  font-weight: 700;
  margin-top: 70px;
  text-align: center;
  margin-right: 30px;

  p {
    margin: 0;
  }
`;
const BigText1 = styled.div`
  font-size: 40px;
  font-weight: 700;
  margin-top: 70px;

  P {
    margin: 0;
  }
`;
const BigText = styled.div`
  color: white;
  font-size: 40px;
  font-weight: 700;
  margin-top: 70px;
  text-align: center;
  margin: 20px 0px;

  p {
    margin: 0;
  }
`;
const Get = styled.div`
  width: 200px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid silver;
  color: white;
`;
const Button1 = styled.div`
  width: 200px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  background-color: red;

  :hover {
    cursor: pointer;
    background-color: black;
    color: white;
  }
`;
const Our = styled.div`
  width: 200px;
  height: 60px;
  background-color: red;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: white;

  :hover {
    cursor: pointer;
    background-color: black;
    color: white;
  }
`;
const Button = styled.div`
  width: 200px;
  height: 60px;
  background-color: red;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
`;
const Box = styled.div`
  width: 450px;
  height: 100px;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Main = styled.div`
  width: 100%;
  height: 570px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Main2 = styled.div`
  width: 100%;
  height: 570px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding-right: 30px;
  color: white;
  padding-left: 10px;
`;
const Main1 = styled.div`
  width: 100%;
  height: 570px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding-left: 50px;
  margin: 50px 0px;
`;
const Container = styled.div<{ bi: string }>`
  width: 100%;
  height: 570px;
  background-image: ${({ bi }) => bi};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Holder = styled.div`
  width: 100%;
  height: 570px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
